import Button from "./button";
import {useEffect, useState} from "react";
import PrettyPrice from "../../utils/PrettyPrice";
import {Link} from "react-router-dom";
import WaitIcon from '../../images/vectors/wait.svg'
import DeliveringIcon from '../../images/vectors/delivering.svg'
import CheckedIcon from '../../images/vectors/delivered.svg'
import CanceledIcon from '../../images/vectors/canceledIcon.svg'

export default function OrderPreview(props) {

    const [status, setStatus] = useState()
    const [statusIcon, setStatusIcon] = useState()
    const [statusLabel, setStatusLabel] = useState()

    useEffect(() => {
        switch (props.data.status) {
            case 'created' || 'paid':
                setStatus('В обработке')
                setStatusLabel('Обработаем сегодня')
                setStatusIcon(WaitIcon)
                break

            case 'delivering':
                setStatus('В доставке')
                let creationDate = new Date(props.data.creation_date)
                let nextDate = new Date(creationDate)
                nextDate.setDate(creationDate.getDate() + 1)
                setStatusLabel('Ожидается ' + nextDate.toLocaleDateString('ru-ru'))
                setStatusIcon(DeliveringIcon)
                break

            case 'delivered':
                setStatus('Доставлено')
                setStatusLabel(new Date(props.data.delivered_date).toLocaleDateString('ru-ru'))
                setStatusIcon(CheckedIcon)
                break

            case 'cancelled':
                setStatus('Отменен')
                setStatusIcon(CanceledIcon)
                break
        }
    }, []);

    return (
        <div className={'bg-[#f0f0f0] p-10 flex justify-between items-center max-lg:flex-col'}>
            <div>
                <h2 className={'text-3xl'}>Заказ №{props.data.id}</h2>
                {/*<p>Создан {new Date(props.data.creation_date).toLocaleDateString('ru-ru')}</p>*/}
                <p>{PrettyPrice(props.data.totalPrice)}</p>
                <div className={'flex gap-5'}>
                    <Link to={`/order/${props.data.id}/`}>
                        <Button value={'Подробнее'}/>
                    </Link>

                    {
                        props.data.supportChat ? (
                            <Link to={`/chat/${props.data.supportChat.id}`}>
                                <Button value={'Помощь'}/>
                            </Link>
                        ) : (
                            <></>
                        )
                    }
                </div>
            </div>
            <div className={'flex justify-end gap-10 items-center text-3xl w-1/3 max-lg:w-full justify-between'}>
                <div>
                    <p>{status}</p>
                    <p className={'text-sm'}>{statusLabel}</p>
                </div>
                <img src={statusIcon}  alt={''}/>
            </div>
        </div>
    )
}