import HeadMenu from "../components/ui/headmenu";
import TextInput from "../components/ui/TextInput";
import Button from "../components/ui/button";
import HorizontalRow from "../components/ui/HorizontalRow";
import {API_ENDPOINT} from "../constance";
import {useEffect, useState} from "react";
import ProductPreview from "../components/product_preview";
import axios from "axios";
import SelectableMenuItem from "../components/ui/SelectableMenuItem";
import OrderPreview from "../components/ui/OrderPreview";
import VerticalRow from "../components/ui/VerticalRow";
import RichTextInput from "../components/ui/RichTextInput";
import SupportRequestPreview from "../components/ui/SupportRequestPreview";
import Footer from "../components/ui/footer";

export default function Personal() {

    const personalMenu = ['История заказов', 'Избранное', 'Поддержка', 'Параметры']
    const [selectedUnit, setSelectedUnit] = useState(0)

    const [ordersList, setOrdersList] = useState(null)
    const [favoriteList, setFavoriteList] = useState(null)
    const [supportRequests, setSupportRequests] = useState(null)
    const [userData, setUserData] = useState(null)

    const SelectUnit = (n) => {
        setSelectedUnit(n)
    }

    const SendSupportRequest = () => {
        let topic = document.querySelector('#requestTopic')
        let body = document.querySelector('#requestBody')

        const token = localStorage.getItem('access_token')
        axios.post(API_ENDPOINT + "users/support/", {
            topic: topic.value, body: body.value
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((resp) => {
                alert('Created')
                topic.value = ''
                body.value = ''

                axios.get(API_ENDPOINT + "users/support/", {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                    .then((resp) => {
                        setSupportRequests(resp.data)
                        console.log('REQUESTS')
                        console.log(resp.data)
                    })
            })
            .catch((err) => {
                alert('Error' + err)
            })
    }

    useEffect(() => {
        document.title = 'Личный кабинет'
        const token = localStorage.getItem('access_token')
        axios.get(API_ENDPOINT + "users/orders/", {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((resp) => {
                setOrdersList(resp.data)
                console.log(resp.data)
            })
        axios.get(API_ENDPOINT + "users/favorites/", {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((resp) => {
                setFavoriteList(resp.data)
            })
        axios.get(API_ENDPOINT + "users/support/", {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((resp) => {
                setSupportRequests(resp.data)
                console.log('REQUESTS')
                console.log(resp.data)
            })
        axios.get(API_ENDPOINT + 'users/get/', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((resp) => {
                console.log(resp.data.user)
                setUserData(resp.data.user)
            })
    }, []);

    const updateFavorites = () => {
        const token = localStorage.getItem('access_token')
        axios.get(API_ENDPOINT + "users/favorites/", {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((resp) => {
                setFavoriteList(resp.data)
            })
    }

    useEffect(() => {
        personalMenu.forEach((item, index) => {
            if (index !== selectedUnit) {
                document.querySelector(`#unit-${index}`).classList.add('hidden')
                document.querySelector(`#unit-${index}`).classList.remove('block')
            } else {
                document.querySelector(`#unit-${index}`).classList.add('block')
                document.querySelector(`#unit-${index}`).classList.remove('hidden')
            }
        })
    }, [selectedUnit]);


    return (
        <div className={'flex flex-col justify-between min-h-screen'}>
            <div className={'container ms-auto me-auto'}>
                <HeadMenu/>
                <div className={'w-full'}>
                    <h1 className={'text-4xl max-lg:text-2xl'}>Личный кабинет <span
                        className={'text-neutral-300'}> // {personalMenu[selectedUnit]}</span></h1>
                </div>

                <div className={'flex justify-between items-start mt-10 max-lg:flex-col max-lg:w-screen max-lg:p-3'}>
                    <div className={'flex flex-col gap-10 max-lg:gap-2 max-lg:mb-10'}>
                        {personalMenu.map((item, index) => <SelectableMenuItem value={item} ind={index}
                                                                               action={SelectUnit}/>)}
                    </div>
                    <div className={'w-3/4 max-lg:w-full'}>
                        {/*    ИСТОРИЯ ЗАКАЗОВ */}
                        <div id={'unit-0'} className={'block'}>
                            <h2 className={'text-2xl'}>История заказов</h2>
                            <div className={'flex flex-col gap-5'}>
                                {ordersList ? (ordersList.map((order) => <OrderPreview data={order} id={order.id}
                                                                                       key={order.id}/>)) : (
                                    <p>У вас еще не было заказов</p>)}
                            </div>

                        </div>

                        {/*    ИЗБРАННОЕ */}
                        <div id={'unit-1'} className={'hidden'}>
                            <h2 className={'text-2xl'}>Избранное</h2>
                            <div className={'grid grid-cols-4 gap-4 max-lg:grid-cols-2 max-lg:flex-wrap'}>
                                {favoriteList ? (favoriteList.map((item) => <ProductPreview productData={item}
                                                                                            key={item.id}
                                                                                            favorite={true}
                                                                                            updateList={updateFavorites}/>)) : (
                                    <p>В избранном пока пусто</p>)

                                }
                            </div>
                        </div>

                        {/*    ПОДДЕРЖКА */}
                        <div id={'unit-2'} className={'hidden w-full'}>
                            <h2 className={'text-2xl'}>Поддержка</h2>
                            <div className={'mt-5 p-3 border-2'}>
                                <h3 className={'text-xl'}>Новое обращение</h3>
                                <VerticalRow>
                                    <TextInput hint={'Тема обращения'} inputId={'requestTopic'}/>
                                    <RichTextInput hint={'Текст обращения'} inputId={'requestBody'}/>
                                    <Button action={SendSupportRequest}>Отправить запрос</Button>
                                </VerticalRow>
                            </div>

                            <div className={'mt-5'}>
                                <h3 className={'text-xl'}>Ваши заявления</h3>
                                <div className={'flex flex-col gap-5'}>
                                    {supportRequests && supportRequests.length > 0 ? (supportRequests.map((item) =>
                                        <SupportRequestPreview data={item} key={item.id}/>)) : (
                                        <p>Обращений не зарегистрировано</p>)}
                                </div>
                            </div>
                        </div>

                        {/*    ПАРАМЕТРЫ */}
                        <div id={'unit-3'} className={'hidden'}>
                            <h2 className={'text-2xl'}>Параметры</h2>

                            {userData ? (<div className={'w-1/2 max-lg:w-full'}>
                                <div className={'m-5'}>
                                    <h3 className={'text-xl'}>Общая информация</h3>
                                    <VerticalRow>
                                        <TextInput hint={'Имя'} initValue={userData.firstname}/>
                                        <TextInput hint={'Фамилия'} initValue={userData.surname}/>
                                    </VerticalRow>
                                </div>

                                <div className={'m-5'}>
                                    <h3 className={'text-xl'}>Контактная информация</h3>
                                    <VerticalRow>
                                        <TextInput hint={'Номер телефона'} initValue={userData.mobilePhone}
                                                   readonly={true}/>
                                        <TextInput hint={'Почта'} initValue={userData.email}/>

                                        <TextInput hint={'Имя пользователя в Telegram'} initValue={userData.tg}/>
                                        <TextInput hint={'Страна'} initValue={userData.country}/>
                                        <TextInput hint={'Город'} initValue={userData.city}/>
                                    </VerticalRow>
                                </div>
                            </div>) : (<p>Не получилось получить информацию</p>)}

                        </div>
                    </div>
                </div>

            </div>
            <Footer/>
        </div>

    )
}