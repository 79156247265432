export default function Button(props) {
    return (
        <button onClick={props.action} id={props.buttonId} className={'p-2 bg-black text-white min-w-[150px] mt-5 mb-5 ' + (props.fullw ? 'w-full' : '')} ref={props.ref}>
            {
                props.value ? (
                    props.value
                ) : (
                    props.children
                )
            }
        </button>
    )
}