import {useEffect} from "react";

export default function FilterCheckBox(props) {

    useEffect(() => {
        const checkbox = document.querySelector(`#${props.tag}_filter`)
        checkbox.addEventListener('change', (event) => {
            props.toggleAction(props.tag, checkbox.checked)
        })
    }, []);

    return (
        <div>
            <input type={'checkbox'} name={props.tag} id={props.tag+"_filter"} value={props.tag} className={'filter-checkbox m-1'}/>
            <label htmlFor={props.tag+"_filter"}>{props.label}</label>
        </div>
    )
}