import HeadMenu from "../components/ui/headmenu";
import TextInput from "../components/ui/TextInput";
import Button from "../components/ui/button";
import HorizontalRow from "../components/ui/HorizontalRow";
import {API_ENDPOINT} from "../constance";
import {useEffect, useState} from "react";
import ProductPreview from "../components/product_preview";
import axios from "axios";
import Footer from "../components/ui/footer";

export default function Search() {

    const [searchRes, setSearchRes] = useState(null)
    const [catalog, setCatalog] = useState(null)
    const [query, setQuery] = useState('')
    const [paginationIndex, setPaginationIndex] = useState(1)
    const [paginationArray, setPaginationArray] = useState([])
    const paginationNumber = 3
    const [displayingPage, setDisplayingPage] = useState([])

    const setNewQuery = () => {
        const searchMask = document.querySelector('#searchMaskInput').value
        if (searchMask !== query) {
            setSearchRes(null)
            setCatalog(null)
            setDisplayingPage(null)
            setPaginationIndex(1)
            setPaginationArray([])
        }

        setQuery(searchMask)
    }

    function MovePagination(n) {
        if (paginationIndex + n > 0 && paginationIndex + n <= Math.ceil(catalog.length / paginationNumber)) {
            setPaginationIndex(paginationIndex => paginationIndex + n)
        }
    }

    useEffect(() => {
        document.title = 'Поиск'
        document.querySelector('#searchMaskInput').addEventListener('keypress', function (event) {
            if (event.key === 'Enter') {
                event.preventDefault()
                document.querySelector('#searchBtn').click()
            }
        })
        axios.get(API_ENDPOINT + 'product/search?mask=' + query)
            .then((res) => {
                setSearchRes(res.data)
                setCatalog(res.data.res)
                console.log(res.data.res)
            })
    }, []);

    useEffect(() => {
        axios.get(API_ENDPOINT + 'product/search?mask=' + query)
            .then((res) => {
                setSearchRes(res.data)
                setCatalog(res.data.res)
                console.log(res.data.res)
                let p = []
                for (let i = 1; i <= Math.ceil(res.data.count / paginationNumber); i++) {
                    p.push(i)
                }
                setDisplayingPage(res.data.res.slice((paginationIndex - 1) * paginationNumber, paginationIndex * paginationNumber))
                setPaginationArray(p)
            })
    }, [query]);

    useEffect(() => {
        if (catalog !== null) {
            setDisplayingPage([])
            console.log(catalog.slice((paginationIndex - 1) * paginationNumber, paginationIndex * paginationNumber))
            setDisplayingPage(displayingPage => catalog.slice((paginationIndex - 1) * paginationNumber, paginationIndex * paginationNumber))
        }
    }, [paginationIndex])

    useEffect(() => {
        console.log(displayingPage)
    }, [displayingPage]);

    return (
        <div className={'container m-auto'}>
            <HeadMenu/>
            <h1 className={'text-3xl'}>Поиск</h1>
            <div className={'w-full max-lg:p-2 flex items-center'}>
                <TextInput hint={'Название'} inputId={'searchMaskInput'}/>
                <Button value={'Поиск'} buttonId={'searchBtn'} action={setNewQuery}/>
            </div>

            <div className={'m-4'}>
                {
                    searchRes && (
                        searchRes.count > 0 && (
                            <div className={'flex gap-5 items-center'}>
                                <i className='bx bxs-left-arrow-square cursor-pointer' onClick={() => MovePagination(-1)}></i>
                                {
                                    paginationArray.map((item) => <span
                                        className={'cursor-pointer ' + (paginationIndex === item ? 'underline' : '')}
                                        onClick={() => setPaginationIndex(item)}>{item}</span>)
                                }
                                <i className='bx bxs-right-arrow-square cursor-pointer' onClick={() => MovePagination(1)}></i>
                            </div>
                        )
                    )
                }
            </div>

            <div className={'grid grid-cols-3 max-lg:grid-cols-1 gap-4'}>

                {
                    displayingPage ? (
                        displayingPage.length > 0 ? (
                            displayingPage.map((d) =>
                                <ProductPreview productData={d} key={d.item}/>
                            )
                        ) : (
                            query ? (
                                `По запросу "${query}" товаров не найдено`
                            ) : (
                                <></>
                            )

                        )
                    ) : ''

                }
            </div>
            <Footer />
        </div>
    )
}