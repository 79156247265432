import HeadMenu from "../components/ui/headmenu";
import Button from "../components/ui/button";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {API_ENDPOINT, MEDIA_ROOT} from "../constance";
import ProductInCart from "../components/ui/ProductCartView";
import axios from "axios";
import PrettyPrice from "../utils/PrettyPrice";
import TextInput from "../components/ui/TextInput";
import Footer from "../components/ui/footer";
import MobileInput from "../components/ui/mobileInput";

export default function Cart() {

    const [cartContent, setCartContent] = useState(null)
    const [totalPrice, setTotalPrice] = useState(0)
    const [email, setEmail] = useState(null)
    const [address, setAddress] = useState(null)
    const [phoneNumber, setPhoneNumber] = useState(null)
    const [receiverFullname, setReceiverFullname] = useState(null)

    const [orderErrorNoData, setOrderErrorNoData] = useState(false)

    useEffect(() => {
        document.title = 'Корзина'
        const token = localStorage.getItem('access_token')
        axios.get(API_ENDPOINT + "users/cart/", {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((resp) => {

                console.log('====')
                console.log(resp.data)
                console.log(cartContent)
                console.log('====')
                console.log("RESP")
                console.log(Object.keys(resp.data))
                if (resp.data.length > 0) {
                    setCartContent(resp.data)
                    let total = 0
                    resp.data.forEach(item => {
                        let amount = item.amount <= item.available ? item.amount : item.available
                        total += item.price * amount
                    })
                    setTotalPrice(total)
                }
            })
    }, [])

    const updateCart = () => {
        setCartContent(null)
        const token = localStorage.getItem('access_token')
        axios.get(API_ENDPOINT + "users/cart/", {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((resp) => {

                console.log('====')
                console.log(resp.data)
                console.log(cartContent)
                console.log('====')
                console.log("RESP")
                console.log(Object.keys(resp.data))
                if (resp.data.length > 0) {

                    setCartContent(resp.data)
                    let total = 0
                    resp.data.forEach(item => {
                        let amount = item.amount <= item.available ? item.amount : item.available
                        total += item.price * amount
                    })
                    setTotalPrice(total)
                }
            })
    }

    const addMoreProduct = (item, size) => {
        axios.post(API_ENDPOINT + 'product/cart/', {
            'productItem': item,
            'productSize': size
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            },
        })
            .then((resp) => {
                updateCart()
            })
    }

    const lessProduct = (item, size) => {
        axios.post(API_ENDPOINT + 'product/cart/remove/', {
            'item': item,
            'size': size
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            },
        })
            .then((resp) => {
                updateCart()
            })
    }

    const MakeOrder = () => {
        setOrderErrorNoData(false)
        let dataOk = true
        const data = [address, email, phoneNumber, receiverFullname]

        data.forEach((value) => dataOk = value !== null)

        if (dataOk) {
            const token = localStorage.getItem('access_token')
            axios.post(API_ENDPOINT + "users/cart/order/", {cart: cartContent, address: address, fullname: receiverFullname, mobilePhone: phoneNumber, email: email}, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then((resp) => {
                    window.location.href = '/personal'
                })
                .catch((error) => {
                    alert('Ошибка сервера. Мы быстро все починим, подождите, пожалуйста.')
                })
        } else {
            setOrderErrorNoData(true)
        }

    }

    return (
        <div className={'min-h-screen flex flex-col justify-between'}>
            <div className="App container ms-auto me-auto z-10">
                <HeadMenu/>
                <div className={'text-start'}>
                    <h1 className={'text-4xl'}>Ваша корзина</h1>
                    {localStorage.getItem('cart')}
                    <div className={'flex gap-5 max-lg:flex-col'}>

                        {
                            cartContent ? (
                                <div className={'flex flex-col gap-5 w-1/2 max-lg:w-full'}>
                                    {
                                        cartContent.map((value) =>
                                            <ProductInCart data={value} key={`${value.id}${value.size}`}
                                                           addMoreProduct={addMoreProduct} lessProduct={lessProduct}/>
                                        )
                                    }
                                    <p className={'text-2xl text-end'}>Итого: {PrettyPrice(totalPrice)}</p>

                                </div>

                            ) : (
                                <div>
                                    <p>Ваша корзина пуста</p>
                                </div>
                            )
                        }
                        <div className={'w-1/3 max-lg:w-full flex flex-col gap-5'}>
                            <div className={'border p-5'}>
                                <h3 className={'text-xl'}>Оформление заказа</h3>
                                <TextInput hint={'E-mail'} hasLabel={true} onChange={(e) => setEmail(e.target.value)}/>
                                <MobileInput setPhone={setPhoneNumber}/>
                                <TextInput hint={'Адрес доставки'} hasLabel={true}
                                           onChange={(e) => setAddress(e.target.value)}/>
                                <TextInput hint={'Фамилия и имя получателя'} hasLabel={true}
                                           onChange={(e) => setReceiverFullname(e.target.value)}/>
                                <Button value={`Оформить заказ`} action={MakeOrder} fullw={true}/>
                                {
                                    orderErrorNoData && (
                                        <p className={'text-sm text-red-500'}>Вы заполнили не все поля</p>
                                    )
                                }
                            </div>
                            {/*<div>*/}
                            {/*    <p>Дополинте свой образ</p>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}