import HeadMenu from "../components/ui/headmenu";
import TextInput from "../components/ui/TextInput";
import Button from "../components/ui/button";
import VerticalRow from "../components/ui/VerticalRow";
import HorizontalRow from "../components/ui/HorizontalRow";
import Selector from "../components/ui/Selector";
import SecretTextInput from "../components/ui/SecretTextInput";
import {API_ENDPOINT} from "../constance";
import axios from "axios";
import {useEffect, useState} from "react";
import MobileInput from "../components/ui/mobileInput";

const {SmsAero, SmsAeroError, SmsAeroHTTPError} = require('smsaero');

export default function SignUp() {

    const [signUpError, setSignUpError] = useState(null)
    const [complete, setComplete] = useState(false)

    const [phoneNumber, setPhoneNumber] = useState(null)

    const [currentUser, setCurrentUser] = useState();

    const [registBtnText, setRegistBtnText] = useState('Готово')

    useEffect(() => {
        document.title = 'Регистрация'
    }, []);

    const SendConfirmCode = (number) => {
        const client = new SmsAero('felix.trof@gmail.com', '7D3HaOGqKD50HHCfuBWaPXsXii1X');
        client.send(number, 'Добро пожаловать в Deal!')
            .then(response => console.log(response))
            .catch(error => console.error(error));
    }
    const SendRegistData = () => {

        const btn = document.querySelector('#regist-btn')

        setRegistBtnText('Секундочку...')

        let data = {
            'email': document.querySelector('#email').value,
            'password': document.querySelector('#password').value,
            'firstname': document.querySelector('#firstname').value,
            'mobilePhone': phoneNumber
        }

        axios.post(API_ENDPOINT + 'users/signup/', data)
            .then((resp) => {
                setComplete(true)
            })
            .catch((error) => {
                switch (error.response.status) {
                    case 400:
                        setSignUpError("Пожалуйста, убедитесь, что вы заполнили все поля. Возможно, пользователь с такими данными зарегистрирован.")
                        break

                    case 500:
                        setSignUpError("Ошибка на сервере, попробуйте позже")
                        break

                    default:
                        setSignUpError("Что-то пошло не так. Попробуйте позже.")
                        break
                }
            })
        setRegistBtnText('Готово')
    }

    return (
        <div>
            <div className={'container m-auto'}>
                <HeadMenu/>
                <div className={'h-screen flex justify-center items-center -mt-40'}>
                    <div className={'border-2 max-lg:border-0 max-sm:mt-10 p-20 text-center'}>
                        <h1 className={'text-2xl'}>Регистрация</h1>
                        {
                            complete ? (
                                <div>
                                    <p>Вы успешно зарегистрированы!</p>
                                </div>
                            ) : (
                                <div>
                                    <VerticalRow>
                                        <HorizontalRow>
                                            <MobileInput setPhone={setPhoneNumber}/>
                                            <TextInput inputId={'email'} hint={'Email'} hasLabel={true}/>
                                        </HorizontalRow>
                                        <HorizontalRow>
                                            <TextInput inputId={'firstname'} hint={'Имя'} hasLabel={true}/>
                                            <SecretTextInput inputId={'password'} hint={'Пароль'} hasLabel={true}/>
                                        </HorizontalRow>
                                    </VerticalRow>
                                    <Button value={registBtnText} id={'regist-btn'} action={SendRegistData}/>
                                    <p className={'text-red-500'}>{signUpError}</p>
                                </div>
                            )

                        }

                    </div>

                </div>
            </div>
        </div>
    )
}