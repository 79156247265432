import HeadMenu from "../components/ui/headmenu";
import TextInput from "../components/ui/TextInput";
import Button from "../components/ui/button";
import VerticalRow from "../components/ui/VerticalRow";
import HorizontalRow from "../components/ui/HorizontalRow";
import Selector from "../components/ui/Selector";
import SecretTextInput from "../components/ui/SecretTextInput";
import {API_ENDPOINT} from "../constance";
import axios from "axios";
import {useEffect, useRef, useState} from "react";
import MobileInput from "../components/ui/mobileInput";

const {SmsAero, SmsAeroError, SmsAeroHTTPError} = require('smsaero');

export default function SignIn() {

    const [loginError, setLoginError] = useState()

    const [loginBtnText, setLoginBtnText] = useState('Войти')

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        document.title = 'Вход в личный кабинет'
    }, []);



    const Login = async (event) => {
        event.preventDefault();

        setLoginError('')
        setLoginBtnText("Секундочку...")

        try {
            const response = await axios.post(API_ENDPOINT + 'users/signin/', {
                username,
                password
            });
            const {refresh, access} = response.data;
            localStorage.setItem('refresh_token', refresh);
            localStorage.setItem('access_token', access);
            console.log('Успешно авторизованы!');
            console.log(response.data)
            window.location = '/'
        } catch (error) {
            console.error(error);
            setLoginBtnText('Войти')
            setLoginError('Проверьте логин и пароль')
        }
    }

        return (
            <div>
                <div className={'container m-auto'}>
                    <HeadMenu/>
                    <div className={'h-screen flex justify-center items-center -mt-40'}>
                        <div className={'border-2 max-lg:border-0 p-20 text-center'}>
                            <h1 className={'text-2xl'}>Вход</h1>

                            <div className={'text-center'}>
                                <VerticalRow>
                                    <MobileInput setPhone={setUsername}/>
                                    <SecretTextInput onChange={(e) => setPassword(e.target.value)} inputId={'loginPassword'} hint={'Пароль'}/>
                                </VerticalRow>
                                <Button action={Login}>{loginBtnText}</Button>
                                <p className={'text-red-500'}>{loginError}</p>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        )
    }