import fsmStruct from '../../utils/FullscreenMenuStructure.json'
import {useEffect, useState} from "react";
import {API_ENDPOINT} from '../../constance'
import axios from "axios";

export default function FullscreenMenu({isMenuShow, toggleAct}) {

    const [struct, setStruct] = useState(fsmStruct)
    const [currentMenu, setCurrentMenu] = useState(fsmStruct.inner)
    const [previousMenu, setPreviousMenu] = useState(fsmStruct)
    const [breadcrumbs, setBreadcrumbs] = useState([])

    useEffect(() => {
        axios.get(API_ENDPOINT + 'tags/groups?id=2')
            .then((resp) => {
                let changed = struct
                changed.inner['Бренды'] = {}
                const brends = Object.values(resp.data[Object.keys(resp.data)])
                console.log("BRANDS")
                console.log(Object.keys(resp))
                console.log(brends)
                changed.inner['Бренды']['inner'] = {}
                brends.forEach((brandKey) => {
                    changed.inner['Бренды']['inner'][brandKey] = {}
                    changed.inner['Бренды']['inner'][brandKey]['link'] = '/catalog?df=' + brandKey
                })
                // changed.inner['Бренды']
                setStruct(changed)
            })
    }, []);

    const SetMenu = (key) => {
        setPreviousMenu(currentMenu)
        setCurrentMenu(currentMenu[key]["inner"])
    }

    const AddBreadcrumbs = (key) => {
        let current = breadcrumbs
        current.push(key)
        setBreadcrumbs(current)

        UpdateMenu(current, true)
    }

    const StepBackBreadcrumbs = () => {
        let current = breadcrumbs
        current.pop()
        setBreadcrumbs(current)

        UpdateMenu(current, false)
    }

    async function UpdateMenu(breads, act) {
        console.log(breads)
        let menu = struct
        setCurrentMenu({})
        await new Promise(r => setTimeout(r, 10));
        breads.forEach((item) => {
                if (menu.inner.hasOwnProperty(item)) {
                    console.log(item)
                    menu = menu.inner[item]
                    console.log(menu)
                }
            }
        )
        console.log(menu)
        if (breads.length === 0) {
            setCurrentMenu(struct.inner)
        } else {
            if (menu.hasOwnProperty("inner")) {
                setCurrentMenu(menu.inner)
            } else {
                if (menu.hasOwnProperty('link')) {
                    window.location.href = menu.link
                }
            }
        }
    }

    return (
        <>
            {
                isMenuShow && (
                    <div
                        className={'fixed top-0 start-0 w-1/3 border-e border-black max-lg:w-screen h-screen bg-white text-black p-20 max-lg:p-5 z-10 text-start'}>
                        <div className={'relative'}>
                            <span className={'absolute top-0 end-0 cursor-pointer'} onClick={toggleAct}>X</span>
                            <div>
                                <div className={'flex flex-col gap-3'}>
                                    <h2 className={'text-6xl'}>Меню</h2>
                                    {
                                        breadcrumbs.length > 0 && (
                                            <p className={'cursor-pointer text-xl'} onClick={StepBackBreadcrumbs}><i
                                                className='bx bx-arrow-back'></i> Назад
                                            </p>
                                        )
                                    }

                                    {/*<p className={'text-3xl'}>*/}
                                    {/*    <span className={'text-neutral-400'}>Главная</span>*/}
                                    {/*    {*/}
                                    {/*        breadcrumbs.map((item) =>*/}
                                    {/*            <span className={'text-neutral-400'}> <i*/}
                                    {/*                className='bx bx-chevron-right'></i> {item} </span>*/}
                                    {/*        )*/}
                                    {/*    }*/}
                                    {/*</p>*/}
                                </div>


                                <div className={'mt-5 text-3xl'}>
                                    {
                                        Object.keys(currentMenu).map((item) =>
                                            <p className={'cursor-pointer mt-2 menu_appear_anim'} onClick={() => {
                                                AddBreadcrumbs(item)
                                            }}>{item}</p>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>


    )
}