import {useEffect, useState} from "react";
import axios from "axios";
import {API_ENDPOINT, MEDIA_ROOT} from "../../constance";
import PrettyPrice from "../../utils/PrettyPrice";
import {Link} from "react-scroll";

export default function ProductInCart(props) {

    const [data, setData] = useState(null)
    const [ok, setOk] = useState(false)

    useEffect(() => {
        if (props.productData === undefined) {
            axios.get(API_ENDPOINT + `product/get?item=${props.data.item}`)
                .then((resp) => {
                    setData(resp.data.data)
                    setOk(resp.data.ok)
                })
        } else {
            setOk(true)
            setData(props.productData)
            console.log("PRODUCT PREVIEW DATA")
            console.log(props.productData)
            alert(props.productData.size)
        }
    }, []);



    return (
        <div className={'border-b border-black mt-2'}>
            {
                ok ? (

                    <div className={'flex justify-between gap-5'}>
                        <div className={'w-48 h-48 aspect-square'}>
                            <a href={`/product/${props.data.item}`}>
                                <img src={MEDIA_ROOT + data.photos[0].image}
                                     className={'object-cover w-full h-full'}/>
                            </a>
                        </div>
                        <div className={'flex justify-between grow'}>
                            <div>
                                <h3 className={'text-2xl'}>{data.title}</h3>
                                <p>Размер: {props.data.size}</p>

                                {
                                    props.data.available >= props.data.amount ? (
                                        <p>Кол-во: <span className={'flex items-center gap-3'}><span><i
                                            className='bx bxs-minus-circle' onClick={() => props.lessProduct(props.data.item, props.data.size)}></i></span> {props.data.amount} <span><i
                                            className='bx bxs-plus-circle' onClick={() => props.addMoreProduct(props.data.item, props.data.size)}></i></span></span></p>
                                    ) : (
                                        <p>Кол-во: <s>{props.data.amount}</s> (Доступно {props.data.available})</p>
                                    )
                                }
                            </div>
                            <div>
                                <p>{PrettyPrice(data.price)}</p>
                            </div>

                        </div>

                    </div>
                ) : (
                    <p>Loading [#{props.item}]...</p>
                )
            }

        </div>
    )
}