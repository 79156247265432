import HeadMenu from "../components/ui/headmenu";
import Button from "../components/ui/button";
import {Link, useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {API_ENDPOINT, MEDIA_ROOT} from "../constance";
import ToCartButton from "../components/ui/AddToCartButton";
import PrettyPrice from "../utils/PrettyPrice";
import axios from "axios";
import Footer from "../components/ui/footer";
import ProductDetailsUserButtons from "../components/ui/ProductDetailsUserButtons";
import ProductPreview from "../components/product_preview";
import Modal from "../components/ui/modal";
import FadedButton from "../components/ui/buttonFaded";
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Showcase from "../components/ui/showcase";

export default function ProductDetails() {

    const {productItem} = useParams()
    const [data, setData] = useState(null)
    const [cartContent, setCartContent] = useState(null)

    const cor = useRef()

    const sizeSelector = useRef()

    const [inspectionSize, setInspectionSize] = useState(null)
    const [inspectionSizeInCart, setInspectionSizeInCart] = useState(0)

    useEffect(() => {
        document.title = 'Загрузка...'

        let userId = localStorage.getItem('uid')

        fetch(API_ENDPOINT + 'product/get/?item=' + productItem + '&avail=true' + `&uid=${userId}`)
            .then(resp => resp.json())
            .then(json => {
                setData(json)
                console.log(json)
                document.title = json.data.title
                getCart()
                setInspectionSize(Object.keys(json['available'])[0])
            })


    }, []);

    const addToFavorites = () => {
        const token = localStorage.getItem('access_token')
        axios.post(API_ENDPOINT + "users/favorites/", {
            'item': data.data.item
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((resp) => {
                let userId = localStorage.getItem('uid')

                fetch(API_ENDPOINT + 'product/get/?item=' + productItem + '&avail=true' + `&uid=${userId}`)
                    .then(resp => resp.json())
                    .then(json => setData(json))
            })
    }

    const getCart = async () => {
        const token = localStorage.getItem('access_token')
        axios.get(API_ENDPOINT + "users/cart/", {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((resp) => {
                console.log("ASYNC CALL WITH RESULT")
                console.log(resp.data)
                return resp.data
            })
    }

    const addProduct = () => {
        const token = localStorage.getItem('access_token')
        const data = {
            'productItem': productItem,
            'productSize': inspectionSize
        }
        axios.post(API_ENDPOINT + "product/cart/", data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((resp) => {
                const token = localStorage.getItem('access_token')
                axios.get(API_ENDPOINT + "users/cart/", {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                    .then((resp) => {
                        console.log("CART DATA")
                        let cart = resp.data
                        console.log(cart)
                        let amount = 0

                        cart.forEach((unit) => {
                            if (unit.item === productItem && unit.size === inspectionSize) {
                                amount = unit.amount
                            }
                        })

                        setInspectionSizeInCart(amount)
                    })
            })
            .catch((err) => {

            })
    }

    const removeProuct = () => {
        axios.post(API_ENDPOINT + 'product/cart/remove/', {
            'item': productItem,
            'size': inspectionSize
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            },
        })
            .then((resp) => {
                const token = localStorage.getItem('access_token')
                axios.get(API_ENDPOINT + "users/cart/", {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                    .then((resp) => {
                        console.log("CART DATA")
                        let cart = resp.data
                        console.log(cart)
                        let amount = 0

                        cart.forEach((unit) => {
                            if (unit.item === productItem && unit.size === inspectionSize) {
                                amount = unit.amount
                            }
                        })

                        setInspectionSizeInCart(amount)
                    })
            })
    }

    const updateInspectionAmount = () => {
        const token = localStorage.getItem('access_token')
        axios.get(API_ENDPOINT + "users/cart/", {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((resp) => {
                console.log("CART DATA")
                let cart = resp.data
                console.log(cart)
                let amount = 0

                cart.forEach((unit) => {
                    if (unit.item === productItem && unit.size === inspectionSize) {
                        amount = unit.amount
                    }
                })

                setInspectionSizeInCart(amount)
            })
    }

    useEffect(() => {
        const token = localStorage.getItem('access_token')
        axios.get(API_ENDPOINT + "users/cart/", {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((resp) => {
                console.log("CART DATA")
                let cart = resp.data
                console.log(cart)
                let amount = 0

                cart.forEach((unit) => {
                    if (unit.item === productItem && unit.size === inspectionSize) {
                        amount = unit.amount
                    }
                })

                setInspectionSizeInCart(amount)
            })
    }, [inspectionSize, productItem]);

    const sliderSettings = {
        dots: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        infinite: false,
        centerMode: false
    }

    return (
        <div>
            <div className="App container m-auto z-10">
                <HeadMenu/>
                <div>
                    {
                        data ? (
                            data.ok ? (
                                <div className={'flex max-lg:flex-col justify-between gap-10 text-start'}>
                                    <div
                                        className={'h-screen max-lg:h-min w-1/2 max-lg:w-full grid grid-cols-[5%_90%_5%] items-center max-lg:grid-cols-1 justify-between'}>
                                        <div className={'max-lg:hidden flex justify-center'}>
                                            <button className={'text-5xl'}
                                                    id={'prev-btn'} onClick={() => {
                                                cor.current.slickPrev()
                                            }}><i
                                                className='bx bx-chevron-left'></i></button>
                                        </div>
                                        <Slider {...sliderSettings} ref={cor}>
                                            {
                                                data.data.photos.map((photo) =>
                                                    <div
                                                        className={'h-screen max-lg:h-[calc(100vh/3*2)] w-1/2 max-lg:w-full'}>
                                                        <img src={MEDIA_ROOT + photo.image}
                                                             className={'object-cover h-full w-full'}/>
                                                    </div>
                                                )
                                            }
                                        </Slider>
                                        <div className={'max-lg:hidden flex justify-center'}>
                                            <button className={'text-5xl'}
                                                    id={'next-btn'}
                                                    onClick={() => {
                                                        cor.current.slickNext()
                                                    }}><i
                                                className={'bx bx-chevron-right'}></i></button>
                                        </div>

                                    </div>

                                    <div className={'basis-2/4 w-1/2 max-lg:w-full p-5'}>
                                        <h1 className={'text-4xl'}>{data.data.title}</h1>
                                        <p className={'text-3xl'}>{PrettyPrice(data.data.price)}</p>

                                        {
                                            Object.keys(data.available).length > 0 ? (
                                                <div className={'mt-5'}>
                                                    <select className={'p-2 bg-neutral-200 pe-20'}
                                                            id={String(data.item) + "_size"}
                                                            onChange={(e) => setInspectionSize(e.target.value)}>
                                                        {
                                                            Object.entries(data.available).map(([key, value]) =>
                                                                <option key={key}>{key}</option>
                                                            )
                                                        }
                                                    </select><br/>

                                                    {
                                                        localStorage.getItem('access_token') !== null ? (
                                                            <ProductDetailsUserButtons productItem={productItem}
                                                                                       favoriteAction={addToFavorites}
                                                                                       data={data}
                                                                                       avail={true}
                                                                                       updateInspectionAmount={updateInspectionAmount}/>

                                                        ) : (
                                                            <div className={'mt-5 p-2 border-2'}>
                                                                <p><Link to={'/signin'}><span
                                                                    className={'text-blue-800'}><u>Авторизуйтесь</u></span></Link>,
                                                                    чтобы добавить товар в корзину</p>
                                                            </div>
                                                        )
                                                    }

                                                </div>
                                            ) : (
                                                <div>

                                                    <div className={'p-3 bg-neutral-200 mt-5'}>
                                                        <p>К сожалению, сейчас этот товар недоступен</p>
                                                    </div>

                                                    <ProductDetailsUserButtons productItem={productItem}
                                                                               favoriteAction={addToFavorites}
                                                                               data={data}
                                                                               avail={false}
                                                                               updateInspectionAmount={updateInspectionAmount}/>
                                                </div>
                                            )
                                        }

                                        {
                                            inspectionSize && (
                                                <div>
                                                    <p>В корзине ({inspectionSize}):
                                                        <span className={'flex gap-4 items-center'}>
                                                            {
                                                                inspectionSizeInCart > 0 && (
                                                                    <i className='bx bxs-minus-circle cursor-pointer'
                                                                       onClick={removeProuct}></i>
                                                                )
                                                            }
                                                            {inspectionSizeInCart}
                                                            <i className='bx bxs-plus-circle cursor-pointer'
                                                               onClick={addProduct}></i>
                                                        </span>
                                                    </p>
                                                </div>
                                            )
                                        }
                                        <p className={'mt-5 whitespace-pre'}>{data.data.description}</p>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <h2 className={'text-2xl'}>Товар не найден
                                    </h2>
                                    <p>Возможно этот товар уже удален или вы перешли по неправильному адресу</p>
                                </div>
                            )
                        ) : (
                            <div className={'h-vh w-vw flex justify-center items-center'}>
                                <div>
                                    <i class='bx bx-loader-alt bx-spin'></i>
                                    <p>Секундочку...</p>
                                </div>
                            </div>
                        )
                    }

                </div>

            </div>
            <div className={'container m-auto mt-10 p-5'}>
                <h2 className={'text-2xl mb-2'}>Может понравиться</h2>
                <Showcase showcaseId={3}/>
            </div>

            <Footer/>
        </div>
    )
}