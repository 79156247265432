import Button from "./button";
import {useEffect, useState} from "react";
import PrettyPrice from "../../utils/PrettyPrice";
import {Link} from "react-router-dom";
import WaitIcon from '../../images/vectors/wait.svg'
import CheckedIcon from '../../images/vectors/checkedIcon.svg'

export default function SupportRequestPreview(props) {

    const [status, setStatus] = useState()
    const [statusIcon, setStatusIcon] = useState()

    useEffect(() => {
        if (props.data.isActive) {
            setStatus('В обработке')
            setStatusIcon(WaitIcon)
        }
        else {
            setStatus('Решено')
            setStatusIcon(CheckedIcon)
        }
    }, []);

    return (
        <div className={'bg-[#f0f0f0] p-10 flex justify-between items-center max-lg:flex-col'}>
            <div>
                <h2 className={'text-2xl'}>Запрос №{props.data.id}</h2>
                <p>Создан {new Date(props.data.date).toLocaleDateString('ru-ru')}</p>
                <p>Тема: {props.data.topic}</p>
                <div className={'flex gap-5'}>
                    <Link to={`/chat/${props.data.chatId}/`}><Button value={'В чат'}/></Link>
                </div>
            </div>
            <div className={'flex justify-end gap-5 items-center'}>
                <p>{status}</p>
                <img src={statusIcon} alt={''}/>
            </div>
        </div>
    )
}