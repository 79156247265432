export default function FadedButton(props) {
    return (
        <button onClick={props.action} id={props.buttonId} className={'p-2 text-neutral-500 min-w-[150px] mt-5 mb-5 hover:text-black transition-all'}>
            {
                props.value ? (
                    props.value
                ) : (
                    props.children
                )
            }
        </button>
    )
}