import TextInput from "./TextInput";
import {useEffect, useState} from "react";

export default function MobileInput({setPhone}) {

    const [number, setNumber] = useState('')
    const [validateError, setValidateError] = useState(false)


    useEffect(() => {
        const regex = /^(\+7|8)\d{10}$/;

        if (number.length > 0) {
            setValidateError(!regex.test('+7' + number.replace(/\s/g, '')))
        }
        else {
            setValidateError(false)
        }

        if (regex.test('+7' + number.replace(/\s/g, ''))) {
            setPhone('+7' + number.replace(/\s/g, ''))
        } else {
            setPhone(null)
        }
    }, [number]);

    return (
        <div className={'w-full mt-2 mb-2'}>
            <p>Номер телефона</p>
            <div className={'flex gap-2 text-start w-full border'}>
                <div className={'p-2'}>
                    +7
                </div>
                <input className={'grow p-2'} type={'text'} placeholder={'9995553311'} onInput={(e) => {
                    setNumber(e.target.value)
                }}/>
            </div>
            {
                validateError && (
                    <p className={'text-red-500 text-sm'}>Неправильный формат номера телефона</p>
                )
            }
        </div>
    )
}