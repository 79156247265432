import HeadMenu from "../components/ui/headmenu";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {API_ENDPOINT} from "../constance";
import axios from "axios";
import Footer from "../components/ui/footer";

export default function BlogPost() {

    const {postId} = useParams()
    const [postContent, setPostContent] = useState(null)

    useEffect(() => {
        document.title = 'Блог Deal'

        axios.get(API_ENDPOINT + `post/get/?id=${postId}`)
            .then((resp) => {
                setPostContent(resp.data.data)
                console.log(resp.data)
                document.title = resp.data.title
            })


    }, []);

    return (
        <div className={'container m-auto max-h-screen'}>
            <HeadMenu/>
            <div>
                {
                    postContent ? (
                        <div>
                            <h1 className={'text-3xl'}>{postContent.title}</h1>

                            <p style={{ whiteSpace: 'pre-wrap' }} >
                                {postContent.content}
                            </p>
                        </div>
                    ) : (
                        <p>Пост не найден</p>
                    )
                }
            </div>
            <Footer />
        </div>
    )
}