import ToCartButton from "./AddToCartButton";
import Button from "./button";
import FadedButton from "./buttonFaded";

export default function ProductDetailsUserButtons(props) {

    const uia = () => {
        props.updateInspectionAmount()
    }

    return (
        <div>
            {
                props.avail && (
                    <ToCartButton updateInspectionAmount={uia} item={props.productItem} sizeSelector={String(props.data.item) + "_size"}/>
                )
            }

            {
                props.data.favorite ? (
                    <FadedButton action={props.favoriteAction}><i className='bx bxs-star'></i> В
                        избранном</FadedButton>
                ) : (
                    <FadedButton action={props.favoriteAction}><i className='bx bx-star'></i> Добавить
                        в избранное</FadedButton>
                )
            }
        </div>
    )
}